import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import { defaults as defaultControls, Control } from 'ol/control';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import MousePosition from 'ol/control/MousePosition';
import { createStringXY } from 'ol/coordinate';
import XYZ from 'ol/source/XYZ';
import TileWMS from 'ol/source/TileWMS';
import { transform } from 'ol/proj';

// var host = '..'; // Deploy qua tomcat với lệnh jar -cvf vtud.war .
// var host = 'http://localhost:8080';
var host = 'https://etrid.com:8443';
var center = transform([106.37, 20.20], 'EPSG:4326', 'EPSG:3857', 'EPSG:900913');
var zoomLevel = 15;
//
// Định nghĩa layer control
//

var LayerControl25 = /*@__PURE__*/ (function(Control) {
    function LayerControl(opt_options) {
        var options = opt_options || {};

        // Lấy phần tử html từ file index.html
        var element = document.getElementById('layer-control-25');

        Control.call(this, {
            element: element,
            target: options.target
        });
    }

    if (Control) LayerControl.__proto__ = Control;
    LayerControl.prototype = Object.create(Control && Control.prototype);
    LayerControl.prototype.constructor = LayerControl;

    return LayerControl;
}(Control));

var LayerControl250 = /*@__PURE__*/ (function(Control) {
    function LayerControl(opt_options) {
        var options = opt_options || {};

        // Lấy phần tử html từ file index.html
        var element = document.getElementById('layer-control-250');

        Control.call(this, {
            element: element,
            target: options.target
        });
    }

    if (Control) LayerControl.__proto__ = Control;
    LayerControl.prototype = Object.create(Control && Control.prototype);
    LayerControl.prototype.constructor = LayerControl;

    return LayerControl;
}(Control));

//
// Create map, giving it a rotate to north control.
//

var layers = [];
var layerOSM = new TileLayer({
    source: new OSM(),
    visible: true
});

var layerXYZ = new TileLayer({
    visible: true, // Đặt mặc định là hiện
    preload: Infinity,
    source: new XYZ({
        url: 'https://mt.google.com/vt/lyrs=s&hl=x-local&src=app&x={x}&y={y}&z={z}',
    }),
});

// Nhóm các lớp Bản đồ nền 1:25K
var layerBanDo25K_Bg = new TileLayer({
    source: new TileWMS({
        url: host + '/geoserver/wms',
        params: { 'LAYERS': 'vtud:bd25k_bg', 'TILED': true },
        serverType: 'geoserver',
        // Countries have transparency, so do not fade tiles:
        transition: 0
    }),
    visible: true
});

// Các lớp Bản đồ chuyên đề 25K



// Nhóm các lớp Bản đồ nền 1:250K
var layerBanDo250K_Bg = new TileLayer({
    source: new TileWMS({
        url: host + '/geoserver/wms',
        params: { 'LAYERS': 'vtud:bd250k_bg', 'TILED': true },
        serverType: 'geoserver',
        // Countries have transparency, so do not fade tiles:
        transition: 0
    }),
    visible: false
});

layers.push(layerOSM);
// layers.push(layerXYZ);
layers.push(layerBanDo25K_Bg);
layers.push(layerBanDo250K_Bg);

var wmsLayerNames = [
    'yttn',
    'ktxh',
    'pbtnpsv',
    'hsttnsvckbttn',
    'ts',
    'nbddb',
    'nbddbdkh',
    'xlbtbb20002010',
    'xlbtbb20002018',
    'xlbtbb20102018',
    'htktsdtn',
    'htonmt',
    'hlbvbb_nd',
    'hlbvbb_vbvbb'
];

var wmsLayers = [];

var _scale = 25;
for (let index = 0; index < wmsLayerNames.length; index++) {
    const element = wmsLayerNames[index];
    var name = element + '-' + _scale;
    var _l = getWMSLayerByName(_scale, name);
    var item = {
        "name": name,
        "layer": _l
    };
    wmsLayers.push(item);
    layers.push(_l);
}

_scale = 250;
for (let index = 0; index < wmsLayerNames.length; index++) {
    const element = wmsLayerNames[index];
    var name = element + '-' + _scale;
    var _l = getWMSLayerByName(_scale, name);
    var item = {
        "name": name,
        "layer": _l
    };
    wmsLayers.push(item);
    layers.push(_l);
}

// Mặc định chuyên đề hsttnsvckbttn-25
var defaultLayer = wmsLayers.filter((l) => l.name == 'yttn-25')[0];
defaultLayer['layer'].setVisible(true);

function getWMSLayerByName(scale, name) {
    return new TileLayer({
        source: new TileWMS({
            url: host + '/geoserver/wms',
            params: { 'LAYERS': 'vtud' + scale + ':' + name, 'TILED': true },
            serverType: 'geoserver',
            transition: 0
        }),
        visible: false
    });
}

var rasterLayerNames = [
    'vnredsat1',
    'spot5-2003',
    'spot5-2010',
    'sentinel2',
    'sentinel2-2018',
    'landsat8',
];

var rasterLayers = [];
for (let index = 0; index < rasterLayerNames.length; index++) {
    const name = rasterLayerNames[index];
    var _l = getRasterLayerByName(name);
    var item = {
        "name": name,
        "layer": _l,
    }
    rasterLayers.push(item);
    layers.push(_l);
}

function getRasterLayerByName(name) {
    return new TileLayer({
        source: new TileWMS({
            url: host + '/geoserver/wms',
            params: { 'LAYERS': 'vtudraster' + ':' + name, 'TILED': true },
            serverType: 'geoserver',
            transition: 0
        }),
        visible: false
    });
}

// Hiển thị tọa độ
var mousePositionControl = new MousePosition({
    coordinateFormat: createStringXY(6),
    projection: 'EPSG:4326',
    className: 'custom-mouse-position',
    target: document.getElementById('mouse-position'),
    undefinedHTML: '&nbsp;'
});

var layer25K = new LayerControl25();
var layer250K = new LayerControl250();

// Điều khiển layer (mặc định)
var layerControl = defaultControls().extend([
    layer25K,
    mousePositionControl
]);

var map = new Map({
    controls: layerControl,
    layers: layers,
    target: 'map',
    view: new View({
        center: center,
        zoom: zoomLevel,
        rotation: 0
    })
});

function banDoChuyenDeVisibleLayer(scale, lr) {
    switch (scale) {
        case 25:
            layerBanDo250K_Bg.setVisible(false);
            layerBanDo25K_Bg.setVisible(true);
            break;

        case 250:
            layerBanDo250K_Bg.setVisible(true);
            layerBanDo25K_Bg.setVisible(false);
            break;
        default:
            layerBanDo250K_Bg.setVisible(false);
            layerBanDo25K_Bg.setVisible(false);
            break;
    }
    var _lr = lr + '-' + scale;
    // ẩn tất cả các bản đồ chuyên đề
    wmsLayers.forEach(element => {
        var n = element['name'];
        var l = element['layer'];
        if (n === _lr) {
            l.setVisible(true);
        } else {
            l.setVisible(false);
        }
    });
    rasterLayers.forEach(element => {
        var n = element['name'];
        var l = element['layer'];
        if (n === _lr) {
            l.setVisible(true);
        } else {
            l.setVisible(false);
        }
    });
}

function rasterVisibleLayer(lr) {
    banDoChuyenDeVisibleLayer(0, '');
    var _lr = lr;
    // ẩn tất cả các bản đồ chuyên đề
    wmsLayers.forEach(element => {
        var n = element['name'];
        var l = element['layer'];
        if (n === _lr) {
            l.setVisible(true);
        } else {
            l.setVisible(false);
        }
    });
    rasterLayers.forEach(element => {
        var n = element['name'];
        var l = element['layer'];
        if (n === _lr) {
            l.setVisible(true);
        } else {
            l.setVisible(false);
        }
    });
}

// Menu item map-1 click
document.getElementById('map-25').addEventListener('click', function(event) {
    layerControl.clear();
    layerControl.push(
        layer25K
    );
    layerControl.push(
        mousePositionControl
    );
    // Ẩn bản đồ nền 250
    layerBanDo250K_Bg.setVisible(false);
    // Hiện bản đồ nền 25
    layerBanDo25K_Bg.setVisible(true);
    // Hiện lớp chuyên đề mặc định
    banDoChuyenDeVisibleLayer(25, 'yttn');
});

// Menu item map-2 click
document.getElementById('map-250').addEventListener('click', function(event) {
    layerControl.clear();
    layerControl.push(
        layer250K
    );
    layerControl.push(
        mousePositionControl
    );
    // Hiện bản đồ nền 250
    layerBanDo250K_Bg.setVisible(true);
    // Ẩn bản đồ nền 25
    layerBanDo25K_Bg.setVisible(false);
    // Hiện lớp chuyên đề mặc định
    banDoChuyenDeVisibleLayer(250, 'yttn');
});

document.getElementById('vnredsat1').addEventListener('click', function(event) {
    layerControl.clear();

    // Ẩn bản đồ nền 250
    layerBanDo250K_Bg.setVisible(false);
    // Ẩn bản đồ nền 25
    layerBanDo25K_Bg.setVisible(false);
    // Hiện lớp chuyên đề mặc định
    rasterVisibleLayer('vnredsat1');
});

document.getElementById('spot5-2003').addEventListener('click', function(event) {
    layerControl.clear();

    // Ẩn bản đồ nền 250
    layerBanDo250K_Bg.setVisible(false);
    // Ẩn bản đồ nền 25
    layerBanDo25K_Bg.setVisible(false);
    // Hiện lớp chuyên đề mặc định
    rasterVisibleLayer('spot5-2003');
});

document.getElementById('spot5-2010').addEventListener('click', function(event) {
    layerControl.clear();

    // Ẩn bản đồ nền 250
    layerBanDo250K_Bg.setVisible(false);
    // Ẩn bản đồ nền 25
    layerBanDo25K_Bg.setVisible(false);
    // Hiện lớp chuyên đề mặc định
    rasterVisibleLayer('spot5-2010');
});

document.getElementById('landsat8').addEventListener('click', function(event) {
    layerControl.clear();

    // Ẩn bản đồ nền 250
    layerBanDo250K_Bg.setVisible(false);
    // Ẩn bản đồ nền 25
    layerBanDo25K_Bg.setVisible(false);
    // Hiện lớp chuyên đề mặc định
    rasterVisibleLayer('landsat8');
});

document.getElementById('sentinel2').addEventListener('click', function(event) {
    layerControl.clear();

    // Ẩn bản đồ nền 250
    layerBanDo250K_Bg.setVisible(false);
    // Ẩn bản đồ nền 25
    layerBanDo25K_Bg.setVisible(false);
    // Hiện lớp chuyên đề mặc định
    rasterVisibleLayer('sentinel2');
});

document.getElementById('sentinel2-2018').addEventListener('click', function(event) {
    layerControl.clear();

    // Ẩn bản đồ nền 250
    layerBanDo250K_Bg.setVisible(false);
    // Ẩn bản đồ nền 25
    layerBanDo25K_Bg.setVisible(false);
    // Hiện lớp chuyên đề mặc định
    rasterVisibleLayer('sentinel2-2018');
});

document.getElementById('hlbvbb-nd').addEventListener('click', function(event) {
    layerControl.clear();

    // // Ẩn bản đồ nền 250
    // layerBanDo250K_Bg.setVisible(false);

    // // Hiện bản đồ nền 25
    // layerBanDo25K_Bg.setVisible(true);

    // Hiện lớp chuyên đề
    banDoChuyenDeVisibleLayer(25, 'hlbvbb_nd');
});

document.getElementById('hlbvbb-vbvbb').addEventListener('click', function(event) {
    layerControl.clear();

    // // Hiện bản đồ nền 250
    // layerBanDo250K_Bg.setVisible(true);

    // // Ẩn bản đồ nền 25
    // layerBanDo25K_Bg.setVisible(false);

    // Hiện lớp chuyên đề
    banDoChuyenDeVisibleLayer(250, 'hlbvbb_vbvbb');
});

// Chọn layer 25.000
document.getElementById('hsttnsvckbttn-25').addEventListener('change', function(event) {
    banDoChuyenDeVisibleLayer(25, 'hsttnsvckbttn');
});
document.getElementById('htonmt-25').addEventListener('change', function(event) {
    banDoChuyenDeVisibleLayer(25, 'htonmt');
});
document.getElementById('ktxh-25').addEventListener('change', function(event) {
    banDoChuyenDeVisibleLayer(25, 'ktxh');
});
document.getElementById('nbddb-25').addEventListener('change', function(event) {
    banDoChuyenDeVisibleLayer(25, 'nbddb');
});
document.getElementById('nbddbdkh-25').addEventListener('change', function(event) {
    banDoChuyenDeVisibleLayer(25, 'nbddbdkh');
});
document.getElementById('pbtnpsv-25').addEventListener('change', function(event) {
    banDoChuyenDeVisibleLayer(25, 'pbtnpsv');
});
document.getElementById('xlbtbb20002010-25').addEventListener('change', function(event) {
    banDoChuyenDeVisibleLayer(25, 'xlbtbb20002010');
});
document.getElementById('xlbtbb20002018-25').addEventListener('change', function(event) {
    banDoChuyenDeVisibleLayer(25, 'xlbtbb20002018');
});
document.getElementById('xlbtbb20102018-25').addEventListener('change', function(event) {
    banDoChuyenDeVisibleLayer(25, 'xlbtbb20102018');
});

// Chọn layer 250.000
document.getElementById('hsttnsvckbttn-250').addEventListener('change', function(event) {
    banDoChuyenDeVisibleLayer(250, 'hsttnsvckbttn');
});
document.getElementById('htktsdtn-250').addEventListener('change', function(event) {
    banDoChuyenDeVisibleLayer(250, 'htktsdtn');
});
document.getElementById('htonmt-250').addEventListener('change', function(event) {
    banDoChuyenDeVisibleLayer(250, 'htonmt');
});
document.getElementById('ktxh-250').addEventListener('change', function(event) {
    banDoChuyenDeVisibleLayer(250, 'ktxh');
});
document.getElementById('nbddb-250').addEventListener('change', function(event) {
    banDoChuyenDeVisibleLayer(250, 'nbddb');
});
document.getElementById('nbddbdkh-250').addEventListener('change', function(event) {
    banDoChuyenDeVisibleLayer(250, 'nbddbdkh');
});
document.getElementById('pbtnpsv-250').addEventListener('change', function(event) {
    banDoChuyenDeVisibleLayer(250, 'pbtnpsv');
});
document.getElementById('ts-250').addEventListener('change', function(event) {
    banDoChuyenDeVisibleLayer(250, 'ts');
});
document.getElementById('xlbtbb20002010-250').addEventListener('change', function(event) {
    banDoChuyenDeVisibleLayer(250, 'xlbtbb20002010');
});
document.getElementById('xlbtbb20002018-250').addEventListener('change', function(event) {
    banDoChuyenDeVisibleLayer(250, 'xlbtbb20002018');
});
document.getElementById('xlbtbb20102018-250').addEventListener('change', function(event) {
    banDoChuyenDeVisibleLayer(250, 'xlbtbb20102018');
});
document.getElementById('yttn-250').addEventListener('change', function(event) {
    banDoChuyenDeVisibleLayer(250, 'yttn');
});